import { useDispatch,} from 'react-redux'
import {  useGetAllProdcccQuery, useGetAllProddddQuery  } from '../slices/productsApi.js'
import { addToCart } from '../slices/cartSlice'


import { ccc, ddd, photo_ccc, photo_ddd } from '../alocal/Titulo.js';

import '../css/zoom2.css';
import '../css/extras.css';





const Pagbb = () => {
  const { data, error, isLoading } = useGetAllProdcccQuery()
  const dispatch = useDispatch()

  function GatAdd () {

    const { data} =  useGetAllProddddQuery();
    const name = data;

    return name;
  }
  var name = GatAdd();


  const handleAddToCart = product => {
    dispatch(addToCart(product))

   
  }

  const imgs = "../gorba/appeti/";
  
  return (
  <div className='mai'>

  {isLoading ? (
    <p> Loading...</p>
  ) : error ? (
    <p>An error occured</p>
  ) : (
    <>
   
      <div className='bdiv'>

      <div className='mam'> 
            <img src={photo_ccc} alt='logo' />
      </div>

<h2>{ccc}</h2>

        {data?.map(product => (
          <div key={product.code} >
            
            {(() => {
            if ( (product.llenar === "")||(product.llenar === null)){
             return(<></>)
            }else if (product.imagen !== '') {
                return (
                  <div>
                    <div className='fotb'></div>
                    <div className='ma2'>
                      <img src={imgs+product.imagen} alt="imagen" ></img>
                    </div>
                  </div>
                )
              } else {
                return <div></div>
              }

            })()}
 {(() => { 
            
            if( product.llenar === "true"){
             return(
             <div class='mafrase'> <p class= 'tit'> {product.frase} </p> </div>);
           }
          })()}
            {(() => {

          if ( (product.llenar === "")||(product.llenar === null) ){
            return(
          <div>
            <div className='raw'>
              <div className='zumu'>
                <div className='ma8'>
                  
                  <p className='titol'>{product.titulo}&nbsp;</p>
                </div>
                <div className='ma1'>
                  
                  <p className='tipo'> {product.descrip}</p>
                </div>
                <div className='ma1'>
                  
                  <p className='titot'> {product.precio}</p>
                </div>
              </div>
            </div>
            <div className="plussa">
            <button className="sensd" onClick={() => handleAddToCart(product)}>+</button>
            </div>
            <div>
                          <div className='mas-space-lis'></div>
                          <div className='spac-line-lis'></div>
                          <div className='m-b20s'> </div>
                        </div>
            </div>

            );

}else{
<></>
}

          })()}
          


          </div>
        ))}
 
  
 


      </div>



      <div className='bdiv'>

      <div className='mam'>
            
            <img src={photo_ddd} alt='logo' />
      </div>
    
      <h2>{ddd}</h2>
        {name?.map(produc => (
          <div key={produc.code} >
            
            {(() => {
            if ( (produc.llenar === "")||(produc.llenar === null)){
             return(<></>)
            }else if (produc.imagen !== '') {
                return (
                  <div>
                    <div className='fotb'></div>
                  <div className='ma2'>
                      <img src={imgs+produc.imagen} alt="imagen" ></img>
                    </div>
                  </div>
                )
              } else {
                return <div></div>
              }

            })()}
 {(() => { 
            
            if( produc.llenar === "true"){
             return(
             <div class='mafrase'> <p class= 'tit'> {produc.frase} </p> </div>);
           }
          })()}

            {(() => {

          if ( (produc.llenar === "")||(produc.llenar === null) ){
            return(
          <div>
            <div className='raw'>
              <div className='zumu'>
                <div className='ma8'>
                  
                  <p className='titol'>{produc.titulo}&nbsp;</p>
                </div>
                <div className='ma1'>
                  
                  <p className='tipo'> {produc.descrip}</p>
                </div>
                <div className='ma1'>
                  
                  <p className='titot'> {produc.precio}</p>
                </div>
              </div>
            </div>
            <div className="plussa">
            <button className="sensd" onClick={() => handleAddToCart(produc)}>+</button>
            </div>
            <div>
                          <div className='mas-space-lis'></div>
                          <div className='spac-line-lis'></div>
                          <div className='m-b20s'> </div>
                        </div>
            </div>

            );

}else{
<></>
}

          })()}
          

          </div>
        ))}
 
  
 
      </div>
    </>
  )}














  











</div>
)
  
}

export default Pagbb
