
import { createApi, fetchBaseQuery  } from '@reduxjs/toolkit/query/react';
// Define a service using a base URL and expected endpoints
export const productsApi = createApi({
  reducerPath: "productsApi",
  
   baseQuery: fetchBaseQuery({ baseUrl: "https://beachapp.qrgr.rest/" }),
 // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:5000/" }),
  endpoints: (builder) => ({
    getAllProdaaa: builder.query({
      query: () => "prodaaa",
    }),
    getAllProdbbb: builder.query({
      query: () => "prodbbb",
    }),
    getAllProdccc: builder.query({
      query: () => "prodccc",
    }),
    getAllProdddd: builder.query({
      query: () => "prodddd",
    }),
    getAllProdeee: builder.query({
      query: () => "prodeee",
    }),
    getAllProdfff: builder.query({
      query: () => "prodfff",
    }),
    getAllProdggg: builder.query({
      query: () => "prodggg",
    }),
    getAllProdhhh: builder.query({
      query: () => "prodhhh",
    }),
    getAllProdiii: builder.query({
      query: () => "prodiii",
    }),
    getAllProdjjj: builder.query({
      query: () => "prodjjj",
    }),
    getAllProdkkk: builder.query({
      query: () => "prodkkk",
    }),
    getAllProdlll: builder.query({
      query: () => "prodlll",
    }),
    getAllProdmmm: builder.query({
      query: () => "prodmmm",
    }),
    getAllProdnnn: builder.query({
      query: () => "prodnnn",
    }),
    getAllProdooo: builder.query({
      query: () => "prodooo",
    }),
    getAllProdppp: builder.query({
      query: () => "prodppp",
    }),
    getAllProdqqq: builder.query({
      query: () => "prodqqq",
    }),
    getAllProdrrr: builder.query({
      query: () => "prodrrr",
    }),
    getAllProdsss: builder.query({
      query: () => "prodsss",
    }),
    getAllProdttt: builder.query({
      query: () => "prodttt",
    }),
    getAllProduuu: builder.query({
      query: () => "produuu",
    }),
    getAllProdvvv: builder.query({
      query: () => "prodvvv",
    }),
    getAllProdwww: builder.query({
      query: () => "prodwww",
    }),
   
  }),
});





export var { 
  useGetAllProdaaaQuery,
  useGetAllProdbbbQuery,
  useGetAllProdcccQuery, 
  useGetAllProddddQuery, 
  useGetAllProdeeeQuery, 
  useGetAllProdfffQuery, 
  useGetAllProdgggQuery, 
  useGetAllProdhhhQuery, 
  useGetAllProdiiiQuery, 
  useGetAllProdjjjQuery, 
  useGetAllProdkkkQuery, 
  useGetAllProdlllQuery, 
  useGetAllProdmmmQuery, 
  useGetAllProdnnnQuery, 
  useGetAllProdoooQuery, 
  useGetAllProdpppQuery, 
  useGetAllProdqqqQuery, 
  useGetAllProdrrrQuery, 
  useGetAllProdsssQuery, 
  useGetAllProdtttQuery, 
  useGetAllProduuuQuery, 
  useGetAllProdvvvQuery, 
  useGetAllProdwwwQuery, 
  } = productsApi;

