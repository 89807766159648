
const aaa = "BREAKFAST";
const bbb = "TOST";
const ccc = "BAGGETS";
const ddd = "PIES";
const eee = "APPETIZERS";// ΤΑ ΜΑΣ μικρι
const fff = "SALADS";// ΤΑ ΜΑΣ μικρι
const ggg = "HOT POT";// ΟΙ ΜΑΣ μικρι
const hhh = "SOUPS";
const iii = "SPAGUETTIS";
const jjj = "FISH";
const kkk = "SWEETS";
const lll = "SOFT DRINKS";
const mmm = "JUICES";
const nnn = "BEERS";
const ooo = "WINES";
const ppp = "WHITE";
const qqq = "ROZE";
const rrr = "RED";
const sss = "SPARLING & SWEET";
const ttt = "OYZO";
const uuu = "DRINKS";
const vvv = "COCKTAILS";
const www = "COFFES";

// const photo_logo ="../gorba/data/terraza2.jpg";
// const photo_omelet = "../gorba/collec/omeleta.jpg";
// const photo_tost = "../gorba/collec/toast_a.jpg";
// const photo_bagget =  "../gorba/collec/baggetes.jpg";
const photo_aaa = "../gorba/collec/omeleta.jpg";
const photo_bbb = "../gorba/collec/toast_a.jpg";
const photo_ccc = "../gorba/collec/baggetes.jpg";
const photo_ddd = "../gorba/collec/spanakopita.jpg";
const photo_ggg = "../gorba/collec/pasta.jpg";
const photo_hhh = "../gorba/collec/desser.png";
const photo_iii = "../gorba/collec/water.jpg";
const photo_jjj = "../gorba/collec/beer.jpg";
const photo_kkk = "../gorba/collec/wine.jpg";
const photo_lll = "../gorba/collec/tzipuro.jpg";
const photo_mmm = "../gorba/collec/freskos_juice.jpg";
const photo_nnn = "../gorba/collec/coffe_e.jpg";
const photo_ooo = "../gorba/collec/wines.jpg";
const photo_ppp = "../gorba/collec/coffe_e.jpg";
const photo_www = "../gorba/collec/cafes.jpg";

export  {aaa, bbb, ccc, ddd, eee,
    fff,
    ggg,
    hhh,
    iii,
    jjj,
    kkk,
    lll,
    mmm,
    nnn,
    ooo,
    ppp,
    qqq,
    rrr,
    sss,
    ttt,
    uuu,
    vvv,
    www,
    photo_aaa,
    photo_bbb,
    photo_ccc,
    photo_ddd,
    photo_ggg,
    photo_hhh,
    photo_iii,
    photo_jjj,
    photo_kkk,
    photo_lll,
    photo_mmm,
    photo_nnn,
    photo_ooo,
    photo_ppp,
    photo_www,};