import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";



const initialState = {
  cartItems: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
  cartTotalQuantity: 0,
  cartTotalAmount: 0,
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart(state, action) {
       //state.cartItems.push(action.payload)
       const existingIndex = state.cartItems.findIndex(
        (item) => item.code === action.payload.code
       );
       if (existingIndex >= 0) {

       
              
      state.cartItems[existingIndex].cartQuantity += 1;

       toast.info("Increased product quantity", {
       position: "bottom-left", });

       }else{
        const tempProduct = {...action.payload, cartQuantity:1};
       state.cartItems.push(tempProduct);

       toast.success(`${action.payload.titulo} added to list`, {
           position: "bottom-left",});

       }

       localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    decreaseCart(state, action) {
      const itemIndex = state.cartItems.findIndex(
        (item) => item.code === action.payload.code
      );

      if (state.cartItems[itemIndex].cartQuantity > 1) {
        state.cartItems[itemIndex].cartQuantity -= 1;

        toast.info("Decreased product quantity", {
          position: "bottom-left",
        });
      } else if (state.cartItems[itemIndex].cartQuantity === 1) {
        const nextCartItems = state.cartItems.filter(
          (item) => item.code !== action.payload.code
        );

        state.cartItems = nextCartItems;

        toast.error("Product removed from cart", {
          position: "bottom-left",
        });
      }

      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
    },

    removeFromCart(state, action) {

     state.cartItems.map((cartItem) => {
        if (cartItem.code === action.payload.code) {
          const nextCartItems = state.cartItems.filter(
            (item) => item.code !== cartItem.code
          );

          state.cartItems = nextCartItems;
          // callback(state.cartItems);
          // state.cartItems = nextCartItems;
      //  var  callback =  useCallback() asi no funciona

      //   callback(state.cartItems);
          
      //     state.cartItems = nextCartItems;

          // toast.error(`${action.payload.titulo} Removed to list` , {
          //   position: "bottom-left",
          // });
        }
        localStorage.setItem("cartItems", JSON.stringify(state.cartItems));
       // callback(state);
        return state;
       // return callback(state);

      });
    },


    getTotals(state, action) {
      let { total, quantity } = state.cartItems.reduce(
        (cartTotal, cartItem) => {
          var { precio, cartQuantity } = cartItem;
          const itemTotal = precio * cartQuantity;

          cartTotal.total += itemTotal;
          cartTotal.quantity += cartQuantity;

          return cartTotal;
        },
        {
          total: 0,
          quantity: 0,
        }
      );
      total = parseFloat(total.toFixed(2));
      state.cartTotalQuantity = quantity;
      state.cartTotalAmount = total;
    },
    clearCart(state, action) {
      state.cartItems = [];
      
      localStorage.setItem("cartItems", JSON.stringify(state.cartItems));

     
    },

  },
});



export const { addToCart,removeFromCart,getTotals, clearCart, decreaseCart} = cartSlice.actions;
export default cartSlice.reducer;


 