import React from 'react'
import {  Link, Outlet } from 'react-router-dom'

// import NavMenu from '../compo/NavMenu';

import '../css/slide.css'
import '../css/almira.css'
import TopNav from '../compo/TopNav';

const Ahome = () => {

  return (
   
<div className='mai'>
      

      <TopNav/> 
     
<Outlet />

<div className="carta">
<Link to='/cart'>  
         <button className="battt" type="button" id="btnModal">List</button>
</Link>
</div>
</div>
     )
}

export default Ahome
