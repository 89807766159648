// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain:process.env.REACT_APP_FIREBASE_AUTH_DOMAIN, 
    projectId:process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket:process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId:process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId:process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId:process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
  };



  // const firebaseConfig = {
  //   apiKey: "AIzaSyBUVQ1G4YsS2vS8K80CW40k7nrw5prWTps",
  //   authDomain: "newfrench-proyect-beach.firebaseapp.com",
  //   projectId: "newfrench-proyect-beach",
  //   storageBucket: "newfrench-proyect-beach.appspot.com",
  //   messagingSenderId: "102372582604",
  //   appId: "1:102372582604:web:8a73a1e309d2df50c0ce69",
  //   measurementId: "G-VRYJ011MJ1"
  // };


// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);



export default firebaseApp;
