import React from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
// import { getFirestore} from 'firebase/firestore'


import {
  addToCart,
  decreaseCart,
  getTotals,
  removeFromCart
} from '../slices/cartSlice.js'

//import '../css_cart/cart.css'
import '../css_cart/cartss.css'

// import { Form, Button, Col, Row } from 'react-bootstrap'

import firebaseApp from '../credenciales'

import {
  getAuth,
  signOut,
  signInWithEmailAndPassword,
  onAuthStateChanged
} from 'firebase/auth'
import CallModal from "../modals/callModal.js";
import MessageModal from "../modals/MessageModal.js";
import OrderModal from "../modals/OrderModal.js";

import { Container } from "react-bootstrap";

 import "bootstrap/dist/css/bootstrap.min.css";



// const firestore = getFirestore(firebaseApp)

const auth = getAuth(firebaseApp)

const Cart = () => {
  const [isCallModal, setIsCallModal] = React.useState(false);
  const [isMessageModal, setIsMessageModal ] = React.useState(false);
  const [isOrderModal, setIsOrderModal] = React.useState(false);
  

  const contra = process.env.REACT_APP_CODI;
  const email = process.env.REACT_APP_MAIL;
  const key = "time";
  const value = "time";
  const num = 3;
  const [usuarioGlobal, setUsuarioGlobal] = useState(null)

  const cart = useSelector(state => state.cart)
  const dispatch = useDispatch()

 
  useEffect(() => {
    onAuthStateChanged(auth, async usuarioFirebase => {
      if (usuarioFirebase) {
        //código en caso de que haya sesión inciada
         setUsuarioGlobal(usuarioFirebase)
      } else {
        //código en caso de que no haya sesión iniciada
        setUsuarioGlobal(null)
      }
    })
   
  },[])

  useEffect(() => {
    dispatch(getTotals())
  }, [cart, dispatch])

  useEffect(() => {
    getItemWithExpiry(key)
  }, [])

 

  async function submitHandler (e) {
    e.preventDefault()

    const correo = e.target.formBasicEmail.value
    const corre = correo +"@"+ email;
    e.target.formBasicEmail.value = ''

    if (correo !== '' || contra !== '') {
      // si está iniciando sesión
      await signInWithEmailAndPassword(auth, corre, contra)
      var lis = ["Order and Table: "  + correo];
      var lino = JSON.stringify(lis);
      var line =  JSON.parse(lino);

      var limase  = localStorage.getItem('lista');
      var ligias = JSON.parse(limase);

      if(!ligias){
        localStorage.setItem('lista', JSON.stringify(line));
        setItemWithExpiry(key, value);
        var max = generateRandomString(num);
        localStorage.setItem('code', JSON.stringify(max))
      }else{

      for (const color of line) {
        ligias.push(color);
      }
     

      localStorage.setItem('lista', JSON.stringify(ligias));

      e.target.formBasicEmail.value = ''
    }
    } else {
      toast.error(" error connect", { position: 'bottom-left' })
    }
  }
 

  

  
  const handleAddToCart = async product => {
    dispatch(addToCart(product))
  }

  const handleDecreaseCart = async product => {
    dispatch(decreaseCart(product))
  }

  const handleRemoveFromCart = async product => {
    dispatch(removeFromCart(product))
  }

  // const handleClearCart = async () => {
  //   dispatch(clearCart())
  // }

  const  generateRandomString = async (num)  => {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let result1= '';
    const charactersLength = characters.length;
    for ( let i = 0; i < num; i++ ) {
        result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result1;

}
 async function setItemWithExpiry(key, value, ttl = 1000000) {
    const now = new Date();
   
    const item = {
        value: value,
        expiry: now.getTime() + ttl
    };

    localStorage.setItem(key, JSON.stringify(item));
}



async function getItemWithExpiry(key) {
  const itemStr = localStorage.getItem(key);

  // If the item doesn't exist, return null
  if (!itemStr) {
      return null;
  }else{

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    localStorage.removeItem('lista');
    localStorage.removeItem('code');
    await signOut(auth);
    return null;
}


  }
  // Compare the expiry time of the item with the current time
 
}



  return ( 
    <Container fluid>
    <CallModal
    isCallModal={isCallModal}
    setIsCallModal={setIsCallModal}
    usuarioGlobal={usuarioGlobal} 
  />
    <MessageModal
    isMessageModal={isMessageModal}
    setIsMessageModal={setIsMessageModal}
    usuarioGlobal={usuarioGlobal} 
  />
   <OrderModal
    isOrderModal={isOrderModal}
    setIsOrderModal={setIsOrderModal}
    usuarioGlobal={usuarioGlobal} 
  />

    <div className='cart-contain w-100 '>
    
      <div className='start-shopp txal-ce w-100 m-b10s m-t20s'>
        <Link to='/ahome/pagaa'>
          <span className='cabece txal-ce w-100'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='20'
              height='20'
              fill='currentColor'
              className='bi bi-arrow-left'
              viewBox='0 0 16 16'
            >
              <path
                fillRule='evenodd'
                d='M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z'
              />
            </svg>
            Continue with your Wish List
          </span>
        </Link>
      </div>
      {(() => {

if (cart.cartItems.length === 0) {
  return(
    <div></div>
  )

}else{
  return(
      <div>
        <div className='cart-it displa w-100'>
          {cart.cartItems &&
            cart.cartItems.map(cartItem => (
              <div key={cartItem.code}>
                <div className='cart-it txal-ce'>
                  {/* <<<<<<< */}

                  <div className='cart-prod txal-ce'>
                    <div>
                      <h3 className='hbb cabece txal-ce w-100'>
                        {cartItem.titulo}
                      </h3>
                      <p>{cartItem.descrip}</p>
                    </div>
                  </div>
                </div>

                <div className='cart-prod aling-para w-100'>
                  <div className='cart-prod aling-para roww w-100'>
                    <div className='count '>
                      <button
                        className='btns btns-less '
                        onClick={() => handleDecreaseCart(cartItem)}
                      >
                        <div className='poss'>-</div>
                      </button>
                    </div>

                    <div className='count bord-1 txal-ce alin'>
                      {cartItem.cartQuantity}
                    </div>

                    <div className='count'>
                      {' '}
                      <button
                        className='btns btns-plus'
                        onClick={() => handleAddToCart(cartItem)}
                      >
                        <div className='possa'>+</div>
                      </button>
                    </div>
                  </div>
                </div>

                <div className='cart-prod aling-para w-100'>
                  <div className='cart-prod aling-para roww w-100'>

                    <div>{cartItem.precio}€</div>

                    <div>
                      <button
                        className='btns btns-dele delet'
                        onClick={() => handleRemoveFromCart(cartItem)}
                      >
                         <div className='possi'>×</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className='mas-space'> </div>
                <div className='spac-line '></div>
                <div className='m-b20s'> </div>
               
                {/* <<<<<<< */}

          {(() => { 
            
            if( cartItem.precio === "true"){
             return(
             <div class='mafrase'> <p class= 'tit'> {cartItem.precio} </p> </div>);
           }

          })()}


              </div>
         ))}
        </div>

      <div className='alin-cont alin displa w-100'>
        {/* <button className='btn-clear alin' onClick={() => handleClearCart()}>
          Clear Wishes
        </button> */}
      </div>
      </div>
  )}
      })()}


      {(() => {
        if (usuarioGlobal) {
          var gat = usuarioGlobal.email
          var resul = gat.substr(0, 3)
          return (
            <>
              <div className='alin-cont alin displa w-100'>
                <h3 className='hbb txal-ce cabece w-100'>Table: {resul}</h3>

                <button
                  className='btn-conect-close alin m-b20s transit'
                  onClick={async() => await signOut(auth)}
                >
                 Cancel Table
                </button>
              </div>
             
             {/* <<<<<<<<<<<<<<<<<<<< */}

             <div className='alin-cont alin displa w-100 m-t20s'>
                  <div className='alin-cont alin displa w-100'>
              <button className='btn-conect alin' onClick={() => {
                     
                     setIsOrderModal(true);
                    }}>
                   Send Your Order
              </button>
              </div>
              </div>
             


{/* <<<<<<<<<<<<<<<<<<<< */}
<div className='alin-cont alin displa w-100 m-t20s'>
                  <div className='alin-cont alin displa w-100'>
              <button className='btn-conect alin' onClick={() => {
                     
                     setIsMessageModal(true);
                    }}>
                    Write a Message to the Waiter
              </button>
              </div>
              </div>

{/* <<<<<<<<<<<<<<<<<<<< */}


<div className='alin-cont alin displa w-100 m-t20s '>
                  <div className='alin-cont alin displa w-100'>
              <button className='btn-conect alin transit' onClick={() => {
                     
                     setIsCallModal(true);
                    }}>
                    Call the Waiter
              </button>
              </div>
              </div>
{/* <<<<<<<<<<<<<<<<<<<< */}


            </>
          )


        } else {
          
          return (
            <div className='hbb cabece txal-ce w-100 m-t20s'>
              {' '}
              <form onSubmit={submitHandler}>
                <div className='alin-cont alin displa w-100'>
                  <div className='alin-cont alin displa w-100'>
                    <label htmlFor='' className='alin m-b10s'>
                      Table number
                    </label>
                    <input
                      type='number'
                      id='formBasicEmail'
                      name='formBasicEmail'
                      placeholder='Enter Your Number Table'
                    />
                  </div>
              
                  <button className='btn-conect alin' type='sumbit'>
                   Enter Table
                  </button>
                </div>
              </form>{' '}
            </div>
          )
        }
      })()}

{(() => {

var lima  = localStorage.getItem('lista');
var lisa = JSON.parse(lima);
if (!lisa) {
  return <div></div>
} else {


  const names = []
  var index = 1
  lisa.forEach(data => {
    names.push(<h6 key={index++}>{data}</h6>)
  })
  return (
    <div className='alin-cont alin displa w-100 m-t20s'>
      <h5>My Orders Sent</h5>
      <div>{names}</div>
    </div>
  )
}
})()}
    </div>
    </Container>
  )
}

export default Cart
