
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";


//import axios from "axios";

const initialState = {
  items: [],
  status: null,
};

  export const productsFetch = createAsyncThunk(
    "products/productsFetch",
    async (id = null, {rejectWithValue} ) => {
      try {
         //  "https:chaoo-online-shop.herokuapp.com/products"
      //    const response = await axios.get(
      //    "http:localhost:5000/products"
      //    );
      //  return response?.data;

      } catch (error) {
        return rejectWithValue(error.response.data)
       
      }
    }
  );

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: {
    [productsFetch.pending]: (state, action) => {
      state.status = "pending";
    },
    [productsFetch.fulfilled]: (state, action) => {
      state.items = action.payload;
      state.status = "success";
    },
    [productsFetch.rejected]: (state, action) => {
      state.status = "rejected";
    },
  },
});

export default productsSlice.reducer;
