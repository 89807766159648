
import { createApi, fetchBaseQuery  } from '@reduxjs/toolkit/query/react';
// Define a service using a base URL and expected endpoints
export const productsApi = createApi({
  reducerPath: "productsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "https://beachapp.qrgr.rest/" }),
 // baseQuery: fetchBaseQuery({ baseUrl: "http://localhost:5000/" }),
  endpoints: (builder) => ({
    getAllProdaaa: builder.query({
      query: () => "grprodaaa",
    }),
    getAllProdbbb: builder.query({
      query: () => "grprodbbb",
    }),
    getAllProdccc: builder.query({
      query: () => "grprodccc",
    }),
    getAllProdddd: builder.query({
      query: () => "grprodddd",
    }),
    getAllProdeee: builder.query({
      query: () => "grprodeee",
    }),
    getAllProdfff: builder.query({
      query: () => "grprodfff",
    }),
    getAllProdggg: builder.query({
      query: () => "grprodggg",
    }),
    getAllProdhhh: builder.query({
      query: () => "grprodhhh",
    }),
    getAllProdiii: builder.query({
      query: () => "grprodiii",
    }),
    getAllProdjjj: builder.query({
      query: () => "grprodjjj",
    }),
    getAllProdkkk: builder.query({
      query: () => "grprodkkk",
    }),
    getAllProdlll: builder.query({
      query: () => "grprodlll",
    }),
    getAllProdmmm: builder.query({
      query: () => "grprodmmm",
    }),
    getAllProdnnn: builder.query({
      query: () => "grprodnnn",
    }),
    getAllProdooo: builder.query({
      query: () => "grprodooo",
    }),
    getAllProdppp: builder.query({
      query: () => "grprodppp",
    }),
    getAllProdqqq: builder.query({
      query: () => "grprodqqq",
    }),
    getAllProdrrr: builder.query({
      query: () => "grprodrrr",
    }),
    getAllProdsss: builder.query({
      query: () => "grprodsss",
    }),
    getAllProdttt: builder.query({
      query: () => "grprodttt",
    }),
    getAllProduuu: builder.query({
      query: () => "grproduuu",
    }),
    getAllProdvvv: builder.query({
      query: () => "grprodvvv",
    }),
    getAllProdwww: builder.query({
      query: () => "grprodwww",
    }),
   
  }),
});





export var { 
  useGetAllProdaaaQuery,
  useGetAllProdbbbQuery,
  useGetAllProdcccQuery, 
  useGetAllProddddQuery, 
  useGetAllProdeeeQuery, 
  useGetAllProdfffQuery, 
  useGetAllProdgggQuery, 
  useGetAllProdhhhQuery, 
  useGetAllProdiiiQuery, 
  useGetAllProdjjjQuery, 
  useGetAllProdkkkQuery, 
  useGetAllProdlllQuery, 
  useGetAllProdmmmQuery, 
  useGetAllProdnnnQuery, 
  useGetAllProdoooQuery, 
  useGetAllProdpppQuery, 
  useGetAllProdqqqQuery, 
  useGetAllProdrrrQuery, 
  useGetAllProdsssQuery, 
  useGetAllProdtttQuery, 
  useGetAllProduuuQuery, 
  useGetAllProdvvvQuery, 
  useGetAllProdwwwQuery, 
  } = productsApi;

