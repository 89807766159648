import React from 'react';

import {BrowserRouter, Route, Routes} from "react-router-dom"

import Index from "./compo/Index.jsx";

import Cart from "./compo/Cart";
import CartGr from "./compo/CartGr";

import Arxixi from "./pages_gr/Arxixi.js";
import ArxixiPagaa from "./pages_gr/PagaaGr.jsx";
import ArxixiPagbb from "./pages_gr/Pagbb.jsx";
import ArxixiPagcc from "./pages_gr/Pagcc.jsx";
import GrPagdd from "./pages_gr/Pagdd.jsx";
import GrPagee from "./pages_gr/Pagee.jsx";
import GrPagff from "./pages_gr/Pagff.jsx";
import GrPaggg from "./pages_gr/Paggg.jsx";
import GrPaghh from "./pages_gr/Paghh.jsx";
import GrPagii from "./pages_gr/Pagii.jsx";
import GrPagjj from "./pages_gr/Pagjj.jsx";
import GrPagkk from "./pages_gr/Pagkk.jsx";
import GrPagll from "./pages_gr/Pagll.jsx";
import GrPagmm from "./pages_gr/Pagmm.jsx";
import GrPagnn from "./pages_gr/Pagnn.jsx";
import GrPagoo from "./pages_gr/Pagoo.jsx";

import Ahome from "./pages_en/Ahome.js";

import Pagaa from "./pages_en/Pagaa.jsx";
import Pagbb from "./pages_en/Pagbb.jsx";
import Pagcc from "./pages_en/Pagcc.jsx";
import Pagdd from "./pages_en/Pagdd.jsx";
import Pagee from "./pages_en/Pagee.jsx";
import Pagff from "./pages_en/Pagff.jsx";
import Paggg from "./pages_en/Paggg.jsx";
import Paghh from "./pages_en/Paghh.jsx";
import Pagii from "./pages_en/Pagii.jsx";
import Pagjj from "./pages_en/Pagjj.jsx";
import Pagkk from "./pages_en/Pagkk.jsx";
import Pagll from "./pages_en/Pagll.jsx";
import Pagmm from "./pages_en/Pagmm.jsx";
import Pagnn from "./pages_en/Pagnn.jsx";
import Pagoo from "./pages_en/Pagoo.jsx";

import NotFound from "./compo/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


function App() {

  return (
    <div className="App">
       <BrowserRouter>
       <ToastContainer/>

          <Routes>
          <Route path="/" element={<Index />} />
        
          <Route path="/arxixi/*" element={<Arxixi />} >
          <Route path="grpagaa" element={< ArxixiPagaa />} />
          <Route path="grpagbb" element={< ArxixiPagbb />} />
          <Route path="grpagcc" element={< ArxixiPagcc />} />
          <Route path="grpagdd" element={< GrPagdd />} />
          <Route path="grpagee" element={< GrPagee />} />
          <Route path="grpagff" element={< GrPagff />} />
          <Route path="grpaggg" element={< GrPaggg />} />
          <Route path="grpaghh" element={< GrPaghh />} />
          <Route path="grpagii" element={< GrPagii />} />
          <Route path="grpagjj" element={< GrPagjj />} />
          <Route path="grpagkk" element={< GrPagkk />} />
          <Route path="grpagll" element={< GrPagll />} />
          <Route path="grpagmm" element={< GrPagmm />} />
          <Route path="grpagnn" element={< GrPagnn />} />
          <Route path="grpagoo" element={< GrPagoo />} />
          </Route>
       

          <Route path="/ahome/*" element={<Ahome />} >
          <Route path="pagaa" element={< Pagaa />} />
          <Route path="pagbb" element={< Pagbb />} />
          <Route path="pagcc" element={< Pagcc />} />
          <Route path="pagdd" element={< Pagdd />} />
          <Route path="pagee" element={< Pagee />} />
          <Route path="pagff" element={< Pagff />} />
          <Route path="paggg" element={< Paggg />} />
          <Route path="paghh" element={< Paghh />} />
          <Route path="pagii" element={< Pagii />} />
          <Route path="pagjj" element={< Pagjj />} />
          <Route path="pagkk" element={< Pagkk />} />
          <Route path="pagll" element={< Pagll />} />
          <Route path="pagmm" element={< Pagmm />} />
          <Route path="pagnn" element={< Pagnn />} />
          <Route path="pagoo" element={< Pagoo />} />
          </Route>
          <Route path="/cart" element={<Cart />} />
          <Route path="/cartgr" element={<CartGr />} />
          <Route path="/not-found" element={<NotFound/>} />
          </Routes>
        
        
      </BrowserRouter>
    
    </div>
  );
}

export default App;
