import React from 'react'
import { Modal, Button } from 'react-bootstrap'
import '../css_cart/cart.css'
import firebaseApp from '../credenciales'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { getFirestore, doc, setDoc, updateDoc } from 'firebase/firestore'

function MessageModalGr ({ isMessageModalGr, setIsMessageModalGr, usuarioGlobal }) {
  const firestore = getFirestore(firebaseApp)

  async function anadirMessage (e) {
    e.preventDefault()
    const users = usuarioGlobal.uid
    const idFrom = users
    const idTo = 'mZ11tlXbIocuQ0Ih2Dki47p03Xk2'
    const type = 0
    const msgs = e.target.formDescripcion.value
    var city = ''
    var car = []

    e.target.formDescripcion.value = ''

    var idmm = new Date().setMilliseconds(123)
    var idss = new Date()
    var ids = String(idmm)

    // var coses = localStorage.getItem('code')
    // console.log("esto es coses", coses);
    // //codes: coses,

    const nvoArrayTareas = [
      //   ...arrayTareas,
      {
        content: city,
        idFrom: idFrom,
        idTo: idTo,
        msgs: msgs,
        timestamp: ids,
        createOn: idss,
        type: type
      }
    ]

    const mass = '1'
    const sure = '2'
    const tareasSegun = [
      {
        mass: mass,
        sure: sure
      }
    ]

    const docuRef = doc(
      firestore,
      `messages`,
      `${idTo}-${users}`,
      `${idTo}-${users}`,
      `${ids}`
    )

    const doRef = doc(firestore, `messages`, `${idTo}-${users}`)

    await setDoc(docuRef, ...nvoArrayTareas).then(
      console.log('esto es nvo ', nvoArrayTareas),

      await updateDoc(doRef, ...tareasSegun).then(
        console.log('esto es tareasSegun ', tareasSegun),

        toast.success(`Στείλατε το μήνυμα σας στον Σερβιτόρο`, {
          position: 'bottom-left',
          autoClose: 3000
        })
      )
    )

    await setIsMessageModalGr(false)

    if (car.length === 0) {
      return null
    } else {
      var limas = localStorage.getItem('lista')
      var ligia = JSON.parse(limas)

      for (const color of car) {
        ligia.push(color)
      }
      localStorage.setItem('lista', JSON.stringify(ligia))
    }
  }

  return (
    <Modal
      show={isMessageModalGr}
      onHide={() => {
        setIsMessageModalGr(false)
      }}
    >
      <Modal.Header>
        <Modal.Title>
          {/* Message to the Waiter */}
          Μήνυμα στον Σερβιτόρο
          
          </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={anadirMessage}>
          <div className='alin-cont alin displa w-100 m-t20s'>
            <div className='alin-cont alin displa w-100'>
              <label htmlFor='' className='alin m-b10s'></label>
              <input
                type='Text'
                id='formDescripcion'
                name='formDescripcion'
                placeholder='Εισαγάγετε Μήνυμα'
              />
            </div>
            <button className='btn-conect alin' type='sumbit'>
              {/* Send the Message to the Waiter */}
              Στείλτε το μήνυμα σας στον σερβιτόρο
            </button>

            <div className='alin-cont alin displa w-100'></div>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={() => {
            setIsMessageModalGr(false)
          }}
        >
          Ακύρωση
          {/* Cancelar */}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default MessageModalGr
