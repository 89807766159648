

const photo_log = "../data/collec/logo_img1.png";
const photo_logo = "../data/collec/logo4.png";
const photo_eden = "../data/collec/eden.jpg";



const pho_row = "../data/collec/rowp.png";



const photo_gr = "../data/collec/greek_gr.png";
const photo_en = "../data/collec/inglis_in.png";


const pho_insta ="../data/collec/insta1.png";
const pho_face = "../data/collec/face2.png";
const pho_tria = "../data/collec/trip.png";

const rut_inst ="https://www.instagram.com//";
const rut_face ="https://www.facebook.com//";
const rut_tria ="https://www.tripadvisor.com/";

export {photo_log,
    photo_logo,
    photo_eden,
    pho_row,
    photo_gr,
    photo_en,
    pho_insta,
    pho_face,
    pho_tria,
    rut_inst,
    rut_face,
    rut_tria,}
