import React, { useState } from 'react'
import {NavLink} from "react-router-dom";
import "../css_nav/TopNav.css";

import '../css/almira.css'
import '../css_cart/links.css'
import {FaBars, FaRegWindowClose} from "react-icons/fa"
const TopNavGr = () => {
    // const menuData = [
    //     {
    //         path:"/arxixi/grpagaa",
    //         name:"Πρωινο & Τοστ"
    //     },
    //     {
    //         path:"/arxixi/grpagbb",
    //         name:"Μπακετες & Πιτες"
    //     },
    //     {
    //         path:"/arxixi/grpagcc",
    //         name:"Ορεκτικα"
    //     },
    //     {
    //         path:"/arxixi/grpagdd",
    //         name:"Σαλατες"
    //     },
    //     {
    //         path:"/arxixi/grpagee",
    //         name:"Μαγειρευτα"
    //     },
    //     {
    //         path:"/arxixi/grpagff",
    //         name:"Σουπα"
    //     },
    //     {
    //         path:"/arxixi/grpaggg",
    //         name:"Μακαροναδες"
    //     },
    //     {
    //         path:"/arxixi/grpaghh",
    //         name:"Ψαρια & Θαλασσινα"
    //     },
    //     {
    //         path:"/arxixi/grpagii",
    //         name:"Γλuκα"
    //     },
    //     {
    //         path:"/arxixi/grpagjj",
    //         name:"Αναψυκτικα"
    //     },
    //     {
    //         path:"/arxixi/grpagkk",
    //         name:"Χυμοι"
    //     },
    //     {
    //         path:"/arxixi/grpagll",
    //         name:"Μπυρες"
    //     },
    //     {
    //         path:"/arxixi/grpagmm",
    //         name:"Οινοκαταλογος"
    //     },
    //     {
    //         path:"/arxixi/grpagnn",
    //         name:"Ποτα & Cocktails"
    //     },
    //     {
    //         path:"/arxixi/grpagoo",
    //         name:"Καφες"
    //     },

    // ]
    const [isOpen, setIsOpen] = useState(false);
    const toggle = ()=> setIsOpen(!isOpen);

    return (
        <div>
             <div className='mov'>
            <div className='logo-al'>
            <img src='https://qrgr.rest/gorba/data/terraza2.jpg' alt='logo' />
            </div>

     
            <nav className="nav">
            <div className='space-nav'></div>
                <div className="bars">
                    < FaBars onClick={toggle} />
                </div>
                
                <div className='menu' style={{left: !isOpen ? "-100%" : "0"}} >
                    <div className='fa-close'> <FaRegWindowClose onClick={toggle} /></div>
                    <div className='m-b20special'></div>
                    <div className='m-b20special'></div>
                  
                    {/* {
                        menuData.map((item)=>(
                           
                            <NavLink to={item.path} key={item.path} 
                
                          
                            style={{
                                textDecoration: 'none'
                               }}
                            >
                                <div className='list_item'  onClick={toggle}> <p> {item.name}</p> </div>
                                <div className='spac-line-lis'></div>
                            </NavLink>

                        ))
                    } */}
                 
                  <div className='list_item'  onClick={toggle}>

                    <NavLink
                      to='/arxixi/grpagaa'
                      className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      }
                     style={{
                        textDecoration: 'none'
                        
                       }}
                    >
                      <p>Πρωινο &#38; Τοστ</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagbb' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      <p> Μπακετες &#38; Πιτες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagcc' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Ορεκτικα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>

                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagdd' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Σαλατες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagee' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Μαγειρευτα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagff' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Σουπα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpaggg' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Μακαροναδες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpaghh' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Ψαρια &#38; Θαλασσινα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagii' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Γλuκα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagjj' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      <p>Αναψυκτικα</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>

                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagkk' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Χυμοι</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagll' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Μπυρες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>

                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagmm' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Οινοκαταλογος</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagnn' className={({ isActive }) =>
                        isActive ? 'link active' : 'link'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Ποτα &#38; Cocktails</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>
                  <div className='list_item'  onClick={toggle}>
                    <NavLink to='/arxixi/grpagoo' className={({ isActive }) =>
                        isActive ? 'link active' : 'link link-1'
                      } style={{
                        textDecoration: 'none'
                       }}>
                      {' '}
                      <p>Καφες</p>{' '}
                    </NavLink>
                  </div>
                  <div className='line-Nav'></div>

                
                
                    
                    </div>
                    <div className='spac_topna'></div>
               
              
            </nav>

        </div>
    </div>
    )
}

export default TopNavGr