import React from 'react'
import {  Link, Outlet } from 'react-router-dom'
// import GrNavMenu from '../compo/NavMenuGr.jsx';
import TopNavGr from '../compo/TopNavGr';
import '../css/slide.css'
import '../css/almira.css'



const Arxixi = () => {
 
  return (
   
    <div className='mai'>
          
          {/* <GrNavMenu/>  */}
          <TopNavGr/> 
         
    <Outlet />
    
    <div className="carta">
    <Link to='/cartgr'>  
             <button className="battt" type="button" id="btnModal">Λίστα</button>
    </Link>
    </div>
    </div>
         )
}

export default Arxixi
