import { NavLink } from 'react-router-dom'

import {
  photo_log,
 
  photo_gr,
  photo_en,
  pho_insta,
  pho_face,
  pho_tria,
  rut_inst,
  rut_face,
  rut_tria
} from '../alocal/index.js'

import '../csstwo/index.css'
import '../csstwo/index2.css'
import '../css/almira.css'
import '../csstwo/medidas.css'

const Index = () => {
  return (
    <div className='mai'>
      <div className='mov'>
        <div className='mov1'></div>
        <section>
          <div className='gran'>
            <div>
              <div className='logo_prin p-t-20 wi-100'>
                <img src={photo_log} alt='' />
              </div>
            </div>

            {/* <div>
              <div className='logos p-t-20 wi-100'>
                <img src={photo_logo} alt='' />
              </div>
            </div> */}

            {/* <div>
              <div className='logos p-t-20 wi-100'>
                <img src={photo_eden} alt='' />
              </div>
            </div> */}

            <div className='menu'>
              <p className='tex-18 tex-c blanc'>ΕΣΤΙΑΤΟΡΙΟ</p>
              <p className='tex-14 tex-c blanc'>BEACH BAR</p>
              <p className='tex-14 tex-c blanc'>ΚΑΤΑΛΟΓΟΣ</p>
            </div>

            <div className='mbdr wi-90'>
              <div className='greek'>

                <NavLink reloadDocument to={'/arxixi/grpagaa'} className="bandera">
                  <img src={photo_gr} alt='flag' />
                </NavLink>

              </div>
      
              <div className='gre'>
           
                <NavLink reloadDocument to={'/ahome/pagaa'} className="bandera">
                  <img src={photo_en} alt='flag' />
                </NavLink>
               
              </div>
           
            </div>
          </div>

          <div className='foot wi-100'>
            <div className='bac'>
              <div className='colu'>

                <p className='tex-14'>GREECE</p>

                <p className='tex-14'>Phone 6977634205</p>
                <p className='tex-14'></p>
              </div>
            </div>

            <div className='line wi-100'></div>

            <div className='logo wi-100'>
              <a href={rut_face}>
                <img src={pho_face} alt='' />
              </a>
              <a href={rut_inst}>
                <img src={pho_insta} alt='' />
              </a>
              <a href={rut_tria}>
                <img src={pho_tria} alt='' />
              </a>
            </div>
          </div>
        </section>
        <div className='mov3'></div>
      </div>
    </div>
  )
}

export default Index
