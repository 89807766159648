import React, { useState } from 'react'
import {NavLink} from "react-router-dom";
import "../css_nav/TopNav.css";

import '../css/almira.css'
import '../css_cart/links.css'
import {FaBars, FaRegWindowClose} from "react-icons/fa"
const TopNav = () => {
    const menuData = [
        {
            path:"/ahome/pagaa",
            name:"Breakfast & Toast"
        },
        {
            path:"/ahome/pagbb",
            name:"Baggettes & Pies"
        },
        {
            path:"/ahome/pagcc",
            name:"Appetizers"
        },
        {
            path:"/ahome/pagdd",
            name:"Salads"
        },
        {
            path:"/ahome/pagee",
            name:"Hot pot"
        },
        {
            path:"/ahome/pagff",
            name:"Soups"
        },
        {
            path:"/ahome/paggg",
            name:"Pasta"
        },
        {
            path:"/ahome/paghh",
            name:"Fish"
        },
        {
            path:"/ahome/pagii",
            name:"Sweets"
        },
        {
            path:"/ahome/pagjj",
            name:"Soft Drinks"
        },
        {
            path:"/ahome/pagkk",
            name:"Juices"
        },
        {
            path:"/ahome/pagll",
            name:"Beers"
        },
        {
            path:"/ahome/pagmm",
            name:"Wines"
        },
        {
            path:"/ahome/pagnn",
            name:"Drinks & Cocktails"
        },
        {
            path:"/ahome/pagoo",
            name:"Coffee"
        },

    ]
    const [isOpen, setIsOpen] = useState(false);
    const toggle = ()=> setIsOpen(!isOpen);

    return (
        <div>
             <div className='mov'>
            <div className='logo-al'>
            <img src='https://qrgr.rest/gorba/data/terraza2.jpg' alt='logo' />
            </div>

     
            <nav className="nav">
            <div className='space-nav'></div>
                <div className="bars">
                    < FaBars onClick={toggle} />
                </div>
                
                <div className='menu' style={{left: !isOpen ? "-100%" : "0"}} >
                    <div className='fa-close'> <FaRegWindowClose onClick={toggle} /></div>
                    <div className='m-b20special'> </div>
                    <div className='m-b20special'> </div>
                    {
                        menuData.map((item)=>(
                           
                            <NavLink to={item.path} key={item.name} 
                            className={({ isActive }) =>
                            isActive ? 'link active' : 'link'
                          }
                            style={{
                                textDecoration: 'none'
                                
                               
                               }}
                            >
                                <div className='list_item'  onClick={toggle}> <p> {item.name}</p> </div>
                                <div className='spac-line-lis'></div>
                            </NavLink>

                        ))
                    }

                </div>
                <div className='spac_topna'></div>
            </nav>

        </div>
    </div>
    )
}

export default TopNav