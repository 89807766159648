
import React from 'react';

import ReactDOM from 'react-dom/client';
import App from './App';


import './index.css';


import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import { productsApi } from "./slices/productsApi";
import productsReducer, {productsFetch} from "./slices/productsSlice";
import cartReducer from "./slices/cartSlice";

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer: {
     products: productsReducer,
     cart: cartReducer,
    [productsApi.reducerPath]: productsApi.reducer,
  },
   middleware: (getDefaultMiddleware) =>
     getDefaultMiddleware().concat(productsApi.middleware),
});

store.dispatch(productsFetch());

//store.dispatch(getTotals());

root.render(
  <React.StrictMode>
     <Provider store ={store}>
     <App />
     </Provider>
  </React.StrictMode>
);


